<template>
  <AppointmentCreateContent />
</template>

<script>
import AppointmentCreateContent from '@/views/agenda/components/AppointmentCreateContent'

export default {
  name: 'AppointmentCreate',
  components: { AppointmentCreateContent }
}
</script>
