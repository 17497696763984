<template>
  <PageContainer
    :title="$translations.appointment.create.title"
    :back="actions.back"
    :submit="actions.submit"
  >
    <template v-slot:content>
      <div>
        <!-- patient specialist selector as a counseler-->
        <div class="page-segment" v-if="$store.getters['auth/isCounselor']">
          <div class="page-segment-content">
            <template v-if="$route.query.selected_patient">
              <!-- patient selector -->
              <UserSelector
                @changed="setPatient"
                type="PATIENT"
                ref="patientSelector"
              />
            </template>
            <!-- specialists selector -->
            <UserSelector @changed="setSpecialist" type="SPECIALIST" />

            <template v-if="!$route.query.selected_patient">
              <!-- patient selector -->
              <UserSelector
                v-if="form.specialist"
                @changed="setPatient"
                type="PATIENT"
                ref="patientSelector"
              />
            </template>
          </div>
        </div>
        <!-- patient specialist selector -->
        <div class="page-segment" v-else-if="form.specialist">
          <div class="page-segment-content">
            <UserSelector
              @changed="setPatient"
              type="SPECIALIST_PATIENT"
              :specialistId="form.specialist"
              ref="patientSelector"
            />
          </div>
        </div>

        <!-- service selector -->
        <div class="page-segment" v-if="form.patient && form.specialist">
          <div class="page-segment-content">
            <div class="heading-small" v-text="$translations.form.service" />
            <template v-if="services">
              <ModalitySelector
                @changed="setModality"
                :showPresentialOption="hasInPersonModality"
              />

              <template v-if="specialist.address && !form.virtual">
                <div
                  class="title"
                  v-text="
                    `${$translations.appointment.address['consulting-room']} ${specialist.name}`
                  "
                />
                <div class="margin-bottom">
                  <span class="icon-pin-on" />
                  <span
                    class="padding-left-xs"
                    v-text="specialistAddress"
                  />
                </div>
              </template>

              <ServiceSelector
                :service="service"
                :options-data="getServiceOptions"
                @changed="setService"
              />
            </template>

            <template v-else>
              <div class="margin-y">
                <Skeleton width="103px" height="38px" borderRadius="8px" />
              </div>
              <Skeleton
                class="margin-bottom-xs"
                v-for="index in 3"
                :key="index"
                width="100%"
                height="102px"
                borderRadius="8px"
              />
            </template>
          </div>
        </div>

        <!-- date -->
        <div class="page-segment" v-if="form.specialistService">
          <div class="page-segment-content">
            <AppointmentDateSelector
              :specialist-id="form.specialist"
              :service-id="form.specialistService"
              :modality="form.modality"
              @changed="setDate"
              @dayChanged="dayChanged"
            />
          </div>
        </div>

        <!-- reason -->
        <div class="page-segment" v-if="form.starts_at">
          <div class="page-segment-content">
            <div
              class="heading-small margin-bottom"
              v-text="$translations.appointment.detail.reason"
            />
            <TextareaField
              v-model="form.reason"
              className="text-area margin-top-zero"
              :label="$translations.form['textarea-placeholder']"
              :value="form.reason"
              @change="setReason"
            />
          </div>
        </div>

        <!-- summary -->
        <div class="page-segment" v-if="form.starts_at">
          <div class="page-segment-content">
            <div
              class="heading-small margin-bottom"
              v-text="$translations.form.summary"
            />
            <SpecialistsAppointmentCard :appointment="appointment" />
          </div>
        </div>

        <!-- payment -->
        <div class="page-segment" v-if="form.starts_at">
          <div class="page-segment-content">
            <div
              class="heading-small margin-bottom"
              v-text="$translations.form.payment"
            />
            <!-- origin view session -->
            <div class="margin-y">
              <List
                :options="listOptions"
                type="SELECT"
                @clicked="handlePaymentMethodSelected"
                ref="paymentMethodsList"
              />
            </div>
            <InfoBanner
              v-if="infoBannerText"
              icon="icon-warning-circle-off"
              icon-color="gray-50"
              :text="infoBannerText"
            />
          </div>
        </div>
      </div>
    </template>
  </PageContainer>
</template>

<script>
import SpecialistsAppointmentCard from '@/components/cards/SpecialistsAppointmentCard'
import AppointmentDateSelector from '@/components/fields/AppointmentDateSelector'
import ServiceSelector from '@/components/fields/ServiceSelector'
import ModalitySelector from '@/components/fields/ModalitySelector'
import UserSelector from '@/components/fields/UserSelector'
import {
  Appointments,
  List,
  PageContainer,
  InfoBanner,
  Skeleton
} from '@seliaco/red-panda'
import { TextareaField } from '@seliaco/sea-otter'
import AppointmentCreateMixin from '@/mixins/appointment-create-mixin'

export default {
  name: 'AppointmentCreateContent',
  components: {
    SpecialistsAppointmentCard,
    AppointmentDateSelector,
    ServiceSelector,
    ModalitySelector,
    UserSelector,
    TextareaField,
    List,
    InfoBanner,
    PageContainer,
    Skeleton
  },
  mixins: [AppointmentCreateMixin],
  data () {
    return {
      actions: {
        back: {
          callback: () => {
            this.$route.query.origin_appointment_id
              ? this.goToAppointmentDetail()
              : this.$router.go(-1)
          }
        },
        submit: {
          text: this.$translations.appointment.create.submit,
          callback: () => this.appointmentCreate(),
          loading: this.creating,
          disabled: true
        }
      },
      infoBannerText: null,
      optionSelected: null
    }
  },
  methods: {
    appointmentCreate () {
      if (!this.isValid) {
        return
      }

      this.creating = true

      const cancellation = {
        policy: null
      }

      if (this.form.is_automatic_cancellation_enabled) {
        if (this.optionSelected === '24_HOURS') {
          cancellation.policy = 'TWENTY_FOUR_HOURS'
        } else if (this.optionSelected === '24_HOURS_BEFORE') {
          cancellation.policy = 'TWENTY_FOUR_HOURS_BEFORE_APPOINTMENT'
        }
      }

      const body = {
        reason: this.form.reason,
        internal_note: this.form.internal_note,
        status: 'PENDING',
        origin: 'SPECIALIST',
        specialist_service: this.form.service,
        specialist: this.form.specialist,
        patient: this.form.patient,
        virtual: this.form.virtual,
        starts_at: this.form.starts_at,
        created_by: this.$store.getters['auth/specialist'].id,
        cancellation,
        consume_package: false,
        consume_benefit: false
      }

      Appointments.createSpe(body)
        .then((appointment) => {
          this.$toast({
            severity: 'success',
            text: this.$translations.success['appointment-created']
          })
          this.goToAppointmentDetail(appointment.id)
        })
        .catch(() => {
          this.$toast({
            severity: 'error',
            text: this.$translations.error.default
          })
        })
        .finally(() => {
          this.creating = false
        })
    },
    setService (value) {
      this.form.specialistService = value.id
      this.service = value.service
      this.form.service = value.service.id
      this.form.price = value.service.price
      this.form.duration = value.service.duration
      this.optionSelected = ''
      this.appointment.specialist_service.icon_name = value.service.icon_name
      this.appointment.specialist_service.name = value.service.name
      this.appointment.specialist_service.duration = value.service.duration
      this.setDate()
    },
    goToAppointmentDetail (appointmentId) {
      this.$router.push({
        name: 'AppointmentDetail',
        params: {
          id: this.$route.query.origin_appointment_id || appointmentId
        }
      })
    },
    handlePaymentMethodSelected (paymentMethod) {
      this.optionSelected = paymentMethod.type
      switch (paymentMethod?.type) {
        case '24_HOURS':
          this.form.is_automatic_cancellation_enabled = true
          this.infoBannerText =
            this.$translations.appointment.create['payment-expired-on']
          break
        case 'IN_APPOINTMENT':
          this.form.is_automatic_cancellation_enabled = false
          this.infoBannerText =
            this.$translations.appointment.create['payment-expired-off']
          break
        case '24_HOURS_BEFORE':
          this.form.is_automatic_cancellation_enabled = true
          this.infoBannerText =
            this.$translations.appointment.create['payment-expired-before-on']
          break
      }
    }
  },
  computed: {
    isValid () {
      return Boolean(
        this.form.duration &&
          this.form.service &&
          this.form.specialist &&
          this.form.patient &&
          this.form.starts_at &&
          this.optionSelected
      )
    },
    listOptions () {
      const dateTime = this.$luxon.DateTime.now().plus({ hours: 24 })
      const dateStart = this.$luxon.DateTime.fromJSDate(this.form.starts_at)
      const isBefore24Hours = dateTime < dateStart
      const translations = this.$translations.appointment.create
      let options = [
        {
          text: translations['payment-appointment'],
          type: 'IN_APPOINTMENT'
        }
      ]

      if (isBefore24Hours) {
        options = [
          {
            text: translations['payment-expired'],
            type: '24_HOURS'
          },
          {
            text: translations['payment-expired-before'],
            type: '24_HOURS_BEFORE'
          },
          ...options
        ]
      }

      return options
    },
    specialistAddress () {
      return this.specialist.address.full_address || this.specialist.address
    }
  },
  watch: {
    isValid: {
      handler (val) {
        this.actions.submit.disabled = !val
      },
      immediate: true,
      deep: true
    }
  }
}
</script>
